import React from "react";
import classnames from "classnames";

function IconButton({
  label,
  className,
  type = "button",
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & { label: string }) {
  return (
    <button
      {...props}
      type={type}
      aria-label={label}
      className={classnames(
        "w-auto",
        "focus-visible:ounline-none",
        "bg-transparent",
        "cursor-pointer",
        "p-0",
        "flex",
        "transform",
        className
      )}
    />
  );
}

export default IconButton;
